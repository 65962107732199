const imageSlider = [
    {
        url: 'images/slider/image1.jpg',
        title: 'worki z peletem na palecie'
    },
    {
        url: 'images/slider/image2.jpg',
        title: 'pelet w dłoni'
    },
    {
        url: 'images/slider/image3.jpg',
        title: 'worki z peletem'
    },
]
export default imageSlider